<template>
  <div class="">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
            :data="tableData"
            ref="rfTable"
            style="width: 100%; margin-top: 20px;"
            size="mini">
      <el-table-column
              prop="title"
              label="页面标题">
      </el-table-column>
      <el-table-column
              prop="ftime"
              label="添加时间"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              fixed="right"
              label="选择"
              align="center"
              width="100">
        <template slot-scope="scope">
          <el-button type="danger" size="small" @click="selectRow(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
            background
            @current-change="changePage"
            layout="total, prev, pager, next"
            :total="search.total">
    </el-pagination>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        search: {
          current_page: 1,
          total: 0,
          limit: 5,
          keywords: ''
        },
        tableData: []
      }
    },
    components: {
    },
    props: {
      selectFunc: Function,
      idx: Number
    },
    created() {
      this.getList();
    },
    mounted() {
    },
    methods: {
      getList() {
        var that = this
        that.showLoading()
        var param = {
          page: that.search.current_page,
          page_size: that.search.limit,
          keywords: that.search.keywords
        }
        this.$api.shop.designIndex(param, function (res) {
          that.hideLoading()
          if(res.errcode == 0) {
            that.tableData = res.data.data
            that.search.current_page = res.data.current_page
            that.search.total = res.data.total
          } else {
            that.fail(res.errmsg)
          }
        })
      },
      handleSearch() {
        this.search.current_page = 1
        this.getList()
      },
      changePage(page) {
        this.search.current_page = page
        this.getList()
      },
      selectRow(row) {
        this.selectFunc(row)
      }
    }
  }
</script>

<style scoped>
.el-form-item {
  margin-bottom: 0;
}
</style>
