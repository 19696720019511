<template>
  <div class="">
    <el-form class="demo-form-inline" :inline="true" size="mini">
      <el-form-item label="名称">
        <el-input v-model="search.keywords" placeholder="请输入名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
            :data="tableData"
            @selection-change="selectNews"
            ref="rfTable"
            style="width: 100%;"
            size="mini">
      <el-table-column v-if="type == 2"
              type="selection"
              align="center"
              width="55">
      </el-table-column>
      <el-table-column
              prop="title"
              label="标题">
      </el-table-column>
      <el-table-column
              prop="menu_title"
              label="分类">
      </el-table-column>
      <el-table-column
              prop="ftime"
              label="添加时间"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column v-if="type == 1"
              fixed="right"
              label="选择"
              align="center"
              width="100">
        <template slot-scope="scope">
          <el-button type="danger" size="small" @click="selectRow(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
            background
            @current-change="changePage"
            layout="total, prev, pager, next"
            :page-size="search.limit"
            :total="search.total">
    </el-pagination>
    <el-button type="primary" size="small" @click="chooseOk()" style="position: absolute; bottom: 35px; left: 20px;" v-if="type == 2">确定</el-button>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        search: {
          current_page: 1,
          total: 0,
          limit: 5,
          keywords: ''
        },
        tableData: [],
        chooseList: []
      }
    },
    components: {
    },
    props: {
      selectFunc: Function,
      idx: Number,
      type: Number // 1-单选，2-多选
    },
    created() {
      this.getList();
    },
    mounted() {
    },
    methods: {
      getList() {
        var that = this
        that.showLoading()
        var param = {
          page: that.search.current_page,
          page_size: that.search.limit,
          keywords: that.search.keywords
        }
        this.$api.news.newsIndex(param, function (res) {
          that.hideLoading()
          if(res.errcode == 0) {
            that.tableData = res.data.data
            that.search.current_page = res.data.current_page
            that.search.total = res.data.total
          } else {
            that.fail(res.errmsg)
          }
        })
      },
      handleSearch() {
        this.search.current_page = 1
        this.getList()
      },
      changePage(page) {
        this.search.current_page = page
        this.getList()
      },
      selectRow(row) {
        this.selectFunc(row)
      },
      selectNews(data) {
        this.chooseList = data
      },
      // 多选操作
      chooseOk() {
        if (this.chooseList.length < 1) {
          this.fail('请选择资讯')
        } else {
          this.selectFunc(this.chooseList)
        }
      }
    }
  }
</script>

<style scoped>
</style>
