<template>
  <div class="select-links">
    <div class="" @click="showDropMenu">
      <slot name="button"><el-button type="text">点击选择</el-button></slot>
    </div>

    <el-dialog :visible.sync="dropMenuShow" width="940px" title="设置链接" :append-to-body="true">
      <el-tabs v-model="activeIdx" tab-position="left" style="height: 400px;" v-if="dataList && dataList.length>0">
        <el-tab-pane :label="item.title" :name="'s' + item.id" v-for="(item,index) in dataList" :key="index">
          <div style="max-height: 500px; overflow-y: scroll;" v-if="item.children && item.children.length>0">
            <div class="select-txt" v-for="(child,cindex) in item.children" :key="cindex">
              <div class="title">{{ child.title }}</div>
              <div class="box">
                <div class="item" :class="{active: chooseData.id==three.id}" @click="chooseLink(three)" v-for="(three,tindex) in child.children" :key="tindex">{{ three.title }}</div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div v-else>Loading..</div>
    </el-dialog>

    <el-dialog :visible.sync="linkMod" width="640px" title="自定义链接">
      <el-form label-width="80px">
        <el-form-item label="填写URL：">
          <el-input v-model="chooseData.path" placeholder="将已复制的链接地址粘贴在此处" size="small"></el-input>
        </el-form-item>
      </el-form>
      <div class="" slot="footer" style="overflow: hidden;">
        <el-button type="primary" size="small" @click="chooseData.title='链接 | '+chooseData.path;linkMod=false;confirm()">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="mobileMod" width="640px" title="设置电话">
      <el-form label-width="80px">
        <el-form-item label="填写电话：">
          <el-input v-model="chooseData.path" placeholder="请填写手机号或者电话" size="small"></el-input>
        </el-form-item>
      </el-form>
      <div class="" slot="footer" style="overflow: hidden;">
        <el-button type="primary" size="small" @click="chooseData.title='电话 | '+chooseData.path;mobileMod=false;confirm()">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="pageVisiable" width="640px" title="选择页面">
      <page-mod :selectFunc="res => { chooseData.title='页面 | '+res.title;chooseData.params.id=res.id;pageVisiable=false;confirm() }"></page-mod>
    </el-dialog>

    <el-dialog :visible.sync="newsMenuVisiable" width="640px" title="选择资讯分类">
      <news-menu-mod :type="1" :selectFunc="res => { chooseData.title='资讯分类 | '+res.title;chooseData.params.id=res.id;newsMenuVisiable=false;confirm() }"></news-menu-mod>
    </el-dialog>

    <el-dialog :visible.sync="newsVisiable" width="840px" title="选择资讯">
      <news-mod :type="1" :selectFunc="res => { chooseData.title='资讯 | '+res.title;chooseData.params.id=res.id;newsVisiable=false;confirm() }"></news-mod>
    </el-dialog>

  </div>
</template>

<script>
  import {getFuncPriv} from "@/utils/auth";
  import PageMod from '../../components/PageMod'
  import NewsMod from '../../components/NewsMod'
  import NewsMenuMod from "../../components/NewsMenuMod";
  export default {
    components: {
      PageMod,
      NewsMod,
      NewsMenuMod
    },
    props: {
      index: {
        type: Number,
        default: 0
      },
      setData: [Object , Array],
      setFunc: Function
    },
    watch: {
      'setData': {
        handler (newValue, oldValue) {
          // this.chooseData = newValue
          // if(this.chooseData.parentId) {
          //   this.activeIdx = 's'+this.chooseData.parentId
          //   console.log(this.chooseData)
          //   console.log(this.activeIdx)
          // }
        },
        immediate: true,
        deep: true
      }
    },
    computed: {
    },
    created() {
      this.initPriv()
    },
    data () {
      return {
        dropMenuShow: false,
        dataList: false,
        activeIdx: '',
        chooseData: {},
        linkMod: false,
        pageVisiable: false,
        newsVisiable: false,
        newsMenuVisiable: false,
        mobileMod: false
      }
    },
    methods: {
      initPriv() {
        if(!this.dataList) {
          this.dataList = JSON.parse(getFuncPriv()).func
          this.activeIdx = 's'+this.dataList[0].id
        }
      },
      showDropMenu () {
        this.dropMenuShow = !this.dropMenuShow;
      },
      chooseLink(item) {
        console.log(item)
        if(!this.chooseData || !this.chooseData.id) {
          this.chooseData = JSON.parse(JSON.stringify(item))
          if(!this.chooseData.params) {
            this.chooseData.params = {}
          }
        }
        if(item.authkey=="custom_link") { //自定义链接
          this.linkMod = true
        } else if(item.authkey=="custom_mobile") { //自定义手机号
          this.mobileMod = true
        } else if(item.authkey=='custom_page') { //选择自定义页面
          this.pageVisiable = true
        } else if(item.authkey=="news_menu") { //资讯分类
          this.newsMenuVisiable = true
        } else if(item.authkey=="news_detail") { //资讯详情
          this.newsVisiable = true
        } else {
          this.confirm()
        }
      },
      confirm() {
        this.setFunc(this.chooseData,this.index)
        this.chooseData = {}
        this.dropMenuShow = false
      }
    }
  }
</script>

<style scoped>
  .select-txt {
    padding-left: 10px;
  }
  .select-txt .title {
    line-height: 50px;
    font-weight: 600;
    font-size: 14px;
  }
  .select-txt .box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .select-txt .box .item {
    height: 35px;
    line-height: 35px;
    background-color: #f5f5f5;
    color: #333;
    text-align: center;
    width: 100px;
    border-radius: 3px;
    margin: 0 10px 10px 0;
    cursor: pointer;
  }
  .select-txt .box .item.active {
    background-color: #2d8cf0;
    color: #fff;
  }
</style>
